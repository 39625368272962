import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import { SessionAuthenticator, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { UserSettings } from 'clouden-components/views/user-settings'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'

interface UserSettingsPageProps extends WithTranslation {
  location: any
  sessionContext: SessionContext
  notificationContext: NotificationContext
}

class AuthenticatedUserSettingsPage extends React.Component<UserSettingsPageProps> {
  state = {
  }

  componentDidMount() {
    this.props.sessionContext.fetchMe()
  }

  refreshMe = () => {
    this.props.sessionContext.fetchMe()
  }

  render() {
    return (
      <div className="container">
        <div className="row flex-button-row mt-5 border-bottom">
          <div className="col-lg-8 flex-button-title">
            <h1 className="">User Settings</h1>
          </div>
          <div className="col-lg-4 flex-button-col">
            <button className="btn btn-secondary" onClick={this.refreshMe}><span className={"fa fa-refresh" + (this.props.sessionContext.loadingMe ? " fa-spin" : "")}></span> Refresh</button>
          </div>
        </div>
        <UserSettings location={this.props.location} />
      </div>
    )
  }
}

class UserSettingsPage extends React.Component<UserSettingsPageProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"User Settings"} description="User Settings" />
        <SessionAuthenticator>
          <AuthenticatedUserSettingsPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(UserSettingsPage))
